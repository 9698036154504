//Copyright Breast Cancer Index || Nov 2021
//Main Page

import React from 'react';
import { Link } from "react-router-dom";
import { Dropdown } from 'react-bootstrap';
import { Navbar, FloatingPetal, Footer } from "lib";
import SEO from 'lib/SEO';

import './Home.scss';

import ReactGA from 'react-ga';

class Home extends React.Component {
  render () {
    ReactGA.pageview(window.location.pathname + window.location.search);
    return(
      <>
        <SEO
          title="Breast Cancer Index | Not Another Minute&reg;"
          description="95% Of Women With Early-Stage, Hormone Receptor-Positive Breast Cancer do not benefit from Anti-Estrogen Therapy after 5 years—find out if it is for you."
          />

        <Navbar type="home" showCTA />
        <FloatingPetal type="patient"/>
        <section className="hero">
          <div className="container">
            <div className="column">
              <div className="col-12 col-xs-11 col-sm-10 col-lg-8 col-xl-7 mt-lg-4 text-white hero-content">
                <h1><span>Find your finish</span>{" "}<span>to <span className="nowrap">anti-estrogen</span> therapy</span></h1>
                <div className="sub-heading">
                  Breast Cancer Index<sup>&reg;</sup> (BCI) is the only test recognized by the NCCN Clinical&nbsp;Practice Guidelines in Oncology (NCCN Guidelines<sup>®</sup>) and&nbsp;the ASCO<sup>®</sup> Clinical Practice Guideline to predict which patients are likely to benefit from extension of adjuvant anti-estrogen therapy beyond 5 years.<sup>1,2</sup>
                </div>
              </div>
              <div className="home-hero-nav col-12 col-md-12 col-lg-12 col-xl-10">
                <Link className="btn" to="/recently-been-diagnosed">Recently Diagnosed?</Link>
                <Link className="btn" to="/already-on-estrogen-therapy">On or Restarting Therapy?</Link>
                <Link className="btn" to="/what-to-expect">What the Test Tells You</Link>
                <Link className="btn go-to-providers" to="/resources-providers">For Providers</Link>
              </div>
            </div>
          </div>
        </section>
        <Footer
            showReferences={[true, true]}
            showTopLinks={false} topLinks={[
              { link: "/", label: "For Patients" },
              { link: "/know-someone-with-breast-cancer", label: "For Supporters" }
            ]}
            showSecondaryLinks={false} secondaryLinks={[
              { link: "/order-test", label: "Order The Test" },
              { link: "/contact-providers", label: "Contact" }
            ]}
            type='landing'
          />
      </>
    )
  }

};

export default Home;
