//Copyright Breast Cancer Index || 2021
//Main Page

import './polyfills'; // must be before react!

import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch, Redirect, Route, NavLink, Link } from 'react-router-dom';

import {
    Home,
    ResourcesAbout,
    ResourcesProviders,
    ContactProviders,
    ContactPatients,
    Stories,
    News,
    IndividualStory,
    RecentlyBeenDiagnosed,
    AlreadyOnEndocrineTherapy,
    WhatToExpect,
    KnowSomeoneWithBreastCancer,
    ExploreTheReportPatients,
    FAQs,
    BreastCancerJourney,
    IndividualArticle,
    OrderTest,
    OrderTestPatient,
    ExploreTheReportProviders,
    ProviderLanding,
    PressReleasePage,
    PressReleasePage1211,
    PressReleasePage1210,
    PressReleasePage0603,
    PressReleasePage0513,
    PressReleasePage52920,
    PressReleasePage102920,
    PressReleasePage120820,
    PressReleasePage121720,
    PressReleasePage042522,
    TermsAndConditions,
    NoticeOfPrivacy,
    PrivacyStatement,
    StatementNondiscrimination,
    PrivacyPractice,
    GetBCIThankYou,
    ContactThankYou,
    ProviderThankYou,
} from './pages';

import {
    Article1,
    Article2,
    Article3,
    Article4,
    Article5,
    Article6,
    Article7,
    Article8,
    Article9,
    Article10,
    Article11,
    Article12,
    ArticleObgynPerspectiveOnBCI,
} from './articles';
import ScrollToTop from './lib/ScrollToTop';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/website.scss';

// import vars from './util/vars.json';
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';
// ReactGA.initialize(vars.gaCode);
// TagManager.initialize(vars.gtmCode);

// Fix for floating button jumping on modal popup
function getOperatingSystem(window) {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
        operatingSystem = 'win';
    }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) {
        operatingSystem = 'mac';
    }
    if (window.navigator.appVersion.indexOf('X11') !== -1) {
        operatingSystem = 'unix';
    }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) {
        operatingSystem = 'linux';
    }

    return operatingSystem;
}
const OS = getOperatingSystem(window);
document.querySelector('body').setAttribute('os', OS);

const App = () => (
    <Router>
        <ScrollToTop />
        <Switch>
            <Route path='/resources-providers' component={ResourcesProviders} />
            <Route path='/contact-providers' component={ContactProviders} />
            <Route path='/contact-patients' component={ContactPatients} />
            <Route path='/order-test' component={OrderTest} />
            <Route path='/getting-bci' component={OrderTestPatient} />
            <Route path='/statement-nondiscrimination' component={StatementNondiscrimination} />
            <Route path='/terms-and-conditions' component={TermsAndConditions} />
            <Route path='/Stories' component={Stories} />
            <Route path='/recently-been-diagnosed' component={RecentlyBeenDiagnosed} />
            <Route path='/already-on-estrogen-therapy' component={AlreadyOnEndocrineTherapy} />
            <Route path='/what-to-expect' component={WhatToExpect} />
            <Route path='/know-someone-with-breast-cancer' component={KnowSomeoneWithBreastCancer} />
            <Route path='/explore-the-report-patients' component={ExploreTheReportPatients} />
            <Route path='/explore-the-report-providers' component={ExploreTheReportProviders} />
            <Route path='/faqs' component={FAQs} />
            <Route path='/individual-article/:id' component={IndividualArticle} />
            <Route path='/press-release-page' component={PressReleasePage} />
            <Route path='/press-release-page-1211' component={PressReleasePage1211} />
            <Route path='/press-release-page-1210' component={PressReleasePage1210} />
            <Route path='/press-release-page-0603' component={PressReleasePage0603} />
            <Route path='/press-release-page-0513' component={PressReleasePage0513} />
            <Route path='/press-release-page-52920' component={PressReleasePage52920} />
            <Route path='/press-release-page-102920' component={PressReleasePage102920} />
            <Route path='/press-release-page-121720' component={PressReleasePage121720} />
            <Route path='/press-release-page-12820' component={PressReleasePage120820} />
            <Route path='/press-release-page-042522' component={PressReleasePage042522} />
            <Route path='/success-thank-you' component={ContactThankYou} />
            <Route path='/provider-thank-you' component={ProviderThankYou} />
            <Route path='/get-bci-thank-you' component={GetBCIThankYou} />
            <Route path='/privacy-practices' component={NoticeOfPrivacy} />
            <Route path='/predictive-power-of-bci' component={Article1} />
            <Route path='/bci-is-changing-the-game' component={Article2} />
            <Route path='/prognostic-predictive' component={Article3} />
            <Route path='/10-years-is-it-right' component={Article4} />
            <Route path='/side-effects-and-impact-on-patient-quality-of-life-drives-noncompliance' component={Article5} />
            <Route path='/bci-changes-behavior' component={Article6} />
            <Route path='/prognostic-validation' component={Article7} />
            <Route path='/not-another-minute' component={Article8} />
            <Route path='/bci-clinical-assumptions' component={Article9} />
            <Route path='/ideal-study' component={Article10} />
            <Route path='/predictive-validation' component={Article11} />
            <Route path='/nccn-inclusion' component={Article12} />
            <Route path='/an-obgyns-perspective-on-bci' component={ArticleObgynPerspectiveOnBCI} />
            <Route path='/guidelines'>
                <Redirect to='/nccn-inclusion?utm_source=twitter&amp;utm_medium=social&amp;utm_campaign=ASBrS21' />
            </Route>
            <Route path='/ONS'>
                <Redirect to='/resources-providers?&amp;utm_source=ons&amp;utm_medium=podcast&amp;utm_campaign=hcp' />
            </Route>
            <Route path='/explore-report'>
                <Redirect to='/explore-the-report-patients?utm_source=OfficeImplementationKit&amp;utm_medium=Print&amp;utm_campaign=HCP_OfficeMaterial&amp;utm_content=ExploreReportPatient' />
            </Route>
            <Route path='/report'>
                <Redirect to='/explore-the-report-providers?utm_source=OfficeImplementationKit&amp;utm_medium=Print&amp;utm_campaign=HCP_OfficeMaterial&amp;utm_content=ExploreReportProvider' />
            </Route>
            <Route path='/find-your-finish'>
                <Redirect to='/already-on-estrogen-therapy?utm_source=OfficeImplementationKit&amp;utm_medium=Print&amp;utm_campaign=HCP_OfficeMaterial&amp;utm_content=FindYourFinish' />
            </Route>
            <Route path='/already-on-therapy'>
                <Redirect to='/already-on-estrogen-therapy?utm_source=OfficeImplementationKit&amp;utm_medium=Print&amp;utm_campaign=HCP_OfficeMaterial&amp;utm_content=AlreadyOnTherapy' />
            </Route>
            <Route path='/test-form-2021.pdf'>
                <Redirect to='/order-test' />
            </Route>
            <Route path='/' component={Home} />
        </Switch>
    </Router>
);

render(<App />, document.getElementById('root'));
